
/* TABLE */
td.min-width {
    width: 2% !important;
}
td.min-width-10 {
    width: 10% !important;
}

td.min-width-15 {
    width: 15% !important;
}

.error {
    color: red !important;
}

.required:after {
    content:" *";
    font-size: 1em;
    color: red;
}

a {
    cursor: pointer;
}
.link {
    cursor: pointer;
    color: blue;
}

td, input, textarea, select, option {
    text-transform: uppercase;
}


/* MENU RESPONSIVO */

#menu-responsive {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: red;
}
span.success, span.warning, span.danger, span.primary {
    padding: 5px;
    color: white;
    border-radius: 5px;
}
span.success {
    background: green;
}
span.warning {
    background: orange;
}
span.danger {
    background: red;
}
span.primary {
    background: blue;
}
.modal-title {
    font-size: 1.5em;
}
.modal-70w {
    max-width: 70% !important;
}
.modal-90w {
    max-width: 90% !important;
}
.none {
    display: none;    
}
tr.selected {
    background: rgb(135, 178, 235) !important;
}

#list-images .col {
    position: relative;
}

#list-images .col a#btn-delete-image {
    position: absolute;
    font-weight: bold;
    z-index: 2;
    font-size: 1.5em;
    color: red;
}

.content-color {
    color: #555;
    line-height: 10px;
}


/* -Em aberto: azul escuro
- Aguardando aprovação: amarelo 
- Aprovados: verde
- Pendentes: laranja
- Em produção: lilás 
- Em viagem: azul claro
- Finalizado: Cinza
- Cancelado: vermelho */
span.status  {
    padding: 3px 10px;
    border-radius: 5px;
    font-weight: 600;
}
.status-open{
    background-color: rgb(7, 7, 104);
    color: #FFF;
}
.status-waiting{
    background-color: yellow;
    color: #222;
}
.status-pending{
    background-color: orange;
    color: #222;
}
.status-approved{
    background-color: green;
    color: #FFF;
}
.status-production{
    background-color: rgb(170, 90, 244);
    color: #222;
}
.status-travel{
    background-color: rgb(17, 17, 177);
    color: #FFF;
}
.status-finished{
    background-color: rgb(103, 103, 103);
    color: #FFF;
}
.status-cancel{
    background-color: red;
    color: #FFF;
}
.red {
    color: red;
    font-weight: bold;
}